import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { getToken } from "./api/storage";
import UserContext from "./context/UserContext";
import Sidebar from "./components/Sidebar";

function App() {
  const [user, setUser] = useState(false);

  const checkToken = async () => {
    const token = await getToken();
    if (token) {
      setUser(true);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <UserContext.Provider value={[user, setUser]}>
      <div className="App">{user ? <Sidebar /> : <Outlet />}</div>
    </UserContext.Provider>
  );
}

export default App;
