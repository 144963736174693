// src/pages/TimeTable.js
import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import posttable from "../api/table";
import instance from "../api";

// Fetch the latest file from the table folder
const fetchLatestFileFromTable = async () => {
  const { data } = await instance.get("/api/files/latest/table");
  return data;
};

const TimeTable = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Fetch the latest file from the `table` folder
  const { data: latestFile, refetch } = useQuery(
    ["latestFileFromTable"],
    fetchLatestFileFromTable,
    {
      onSuccess: (data) => {
        const fileUrl = data?.path || null;
        console.log("File URL:", fileUrl);
        setPdfFile(fileUrl);
      },
    }
  );

  // Set up mutation for uploading the file
  const mutation = useMutation(posttable, {
    onSuccess: () => {
      alert("File uploaded successfully!");
      refetch();
    },
    onError: (error) => {
      console.error("Upload error:", error.message);
      alert("Failed to upload the file.");
    },
  });

  const handlePdfSelection = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
      setShowConfirmation(true);
    } else {
      alert("Please select a valid PDF file.");
    }
  };

  const handleConfirmUpload = () => {
    if (selectedFile) {
      mutation.mutate(selectedFile);
      setShowConfirmation(false);
      setSelectedFile(null);
    }
  };

  const handleCancelUpload = () => {
    setShowConfirmation(false);
    setSelectedFile(null);
  };

  return (
    <div className="p-6">
      <div className="sm:flex sm:items-center mb-11">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Time Table Management
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            At this section you can manage your time table.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <label className="block rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600">
            Upload PDF
            <input
              type="file"
              accept="application/pdf"
              className="hidden"
              onChange={handlePdfSelection}
            />
          </label>
        </div>
      </div>

      {/* Confirmation Modal */}
      {showConfirmation && selectedFile && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96">
            <h2 className="text-lg font-bold mb-4">Confirm Upload</h2>
            <p className="mb-4">
              You have selected <strong>{selectedFile.name}</strong>. Are you
              sure you want to upload this file?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
                onClick={handleCancelUpload}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded-md"
                onClick={handleConfirmUpload}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Display Upload Status */}
      {mutation.isLoading && (
        <p className="text-center text-blue-500">Uploading...</p>
      )}
      {mutation.isError && (
        <p className="text-center text-red-500">Failed to upload the file.</p>
      )}
      {mutation.isSuccess && (
        <p className="text-center text-green-500">
          File uploaded successfully!
        </p>
      )}

      {/* Display Latest PDF */}
      {pdfFile ? (
        <div className="flex justify-center items-center">
          <iframe
            src={pdfFile}
            title="Time Table PDF"
            className="w-full h-screen"
            frameBorder="0"
          ></iframe>
        </div>
      ) : (
        <p className="text-center text-gray-500">
          No PDF uploaded. Please upload a PDF to view it here.
        </p>
      )}
    </div>
  );
};

export default TimeTable;
