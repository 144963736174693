import React, { useEffect, useState } from "react";
import instance from "../api/index"; // Axios instance
import { deleteAccountRequest, register } from "../api/auth";
import { useMutation } from "@tanstack/react-query";

const AdminAccountRequests = () => {
  const [accountRequests, setAccountRequests] = useState([]);
  const [userInfo, setUserInfo] = useState({
    civilID: "",
    fullName: "",
    phoneNumber: "",
    fileNumber: "",
    email: "",
    password: "123456",
    confirmPassword: "123456", // Add confirmPassword to state
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAccountRequests = async () => {
      try {
        const { data } = await instance.get("/api/auth/accountRequests"); // API endpoint
        setAccountRequests(data);
        setUserInfo(data);
        setError(null);
      } catch (error) {
        console.error("Error fetching account requests:", error.message);
        setError("Failed to fetch account requests. Please try again.");
      }
    };

    fetchAccountRequests();
  }, []);

  const { mutate: deleteRequest } = useMutation({
    mutationFn: (id) => instance.delete(`/api/auth/deleteAccountRequest/${id}`), // Delete request by ID
    onSuccess: () => {
      alert("Request deleted successfully");
      window.location.reload();
    },
    onError: (error) => {
      console.error("Failed to delete request:", error);
      alert("Failed to delete request");
    },
  });
  const handleApprove = async (id) => {
    try {
      // Find the approved account request
      const approvedRequest = accountRequests.find((req) => req._id === id);

      if (!approvedRequest) {
        console.error("Request not found");
        return;
      }

      // Prepare userInfo for registration
      const userInfo = {
        civilID: approvedRequest.civilID,
        fullName: approvedRequest.fullName,
        email: approvedRequest.email,
        phoneNumber: approvedRequest.phoneNumber,
        fileNumber: approvedRequest.fileNumber,
        password: "123456", // Default password
        confirmPassword: "123456", // Default confirm password
        role: "user", // Default role
      };

      // Send the registration request
      await register(userInfo);

      alert("Account approved and registered successfully");

      // Delete the account

      deleteRequest(id);
    } catch (error) {
      console.error("Error approving request:", error.message);
      alert("Failed to approve and register the account. Please try again.");
    }
  };

  const handleReject = async (id) => {
    try {
      alert("Request rejected successfully");

      // Delete the account request

      deleteRequest(id);
    } catch (error) {
      console.error("Error rejecting request:", error.message);
      alert("Failed to reject the account request. Please try again.");
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-6">Account Requests</h1>

      {error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b text-left font-semibold">
                Full Name
              </th>
              <th className="px-6 py-3 border-b text-left font-semibold">
                Civil ID
              </th>
              <th className="px-6 py-3 border-b text-left font-semibold">
                Email
              </th>
              <th className="px-6 py-3 border-b text-left font-semibold">
                Phone
              </th>

              <th className="px-6 py-3 border-b text-left font-semibold">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {accountRequests.map((request) => (
              <tr key={request._id} className="border-b">
                <td className="px-6 py-4">{request.fullName}</td>
                <td className="px-6 py-4">{request.civilID}</td>
                <td className="px-6 py-4">{request.email}</td>
                <td className="px-6 py-4">{request.phoneNumber}</td>
                <td className="px-6 py-4">
                  <button
                    className="mr-2 bg-green-500 text-white py-1 px-3 rounded hover:bg-green-600"
                    onClick={() => handleApprove(request._id)}
                  >
                    Approve
                  </button>
                  <button
                    className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                    onClick={() => handleReject(request._id)}
                  >
                    Reject
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminAccountRequests;
