// // src/pages/DashBoard.js
// import React, { useEffect } from "react";
// import announcementIcon from "../assets/timetable.png";
// import requestIcon from "../assets/NewRequest.jpg";
// import fileUploadIcon from "../assets/FileUpLode.png";
// import { useNavigate } from "react-router-dom";
// import Card1 from "../components/Card1";
// import GridList1 from "../components/GridList1";
// import GridList2 from "../components/GridList2";

// const DashBoard = () => {
//   const navigate = useNavigate();

//   const isAuthenticated = () => {
//     return !!localStorage.getItem("token");
//   };

//   useEffect(() => {
//     if (!isAuthenticated()) {
//       navigate("/register");
//     }
//   }, [navigate]);

//   const handleAnnouncementsClick = () => {
//     navigate("/timeTable");
//   };

//   const handleRequestClick = () => {
//     navigate("/request");
//   };

//   const handleFileUploadClick = () => {
//     navigate("/fileUpload");
//   };

//   return (
//     <>

//     </>
//   );
// };

// export default DashBoard;

// src/pages/DashBoard.js
import React, { useEffect } from "react";
import announcementIcon from "../assets/timetable.png";
import requestIcon from "../assets/NewRequest.jpg";
import fileUploadIcon from "../assets/FileUpLode.png";
import { useNavigate } from "react-router-dom";

const DashBoard = () => {
  const navigate = useNavigate();

  const isAuthenticated = () => {
    return !!localStorage.getItem("token");
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  }, [navigate]);

  const handleAnnouncementsClick = () => {
    navigate("/timeTable");
  };

  const handleRequestClick = () => {
    navigate("/request");
  };

  const handleFileUploadClick = () => {
    navigate("/fileUpload");
  };

  return (
    <div className="p-6 space-y-6 font-sans">
      {/* Top Row with 6 Data Boxes */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {[...Array(6)].map((_, index) => (
          <div
            className="bg-gray-100 p-6 rounded-lg shadow-md text-center"
            key={index}
          >
            <h3 className="text-xl font-semibold">Data {index + 1}</h3>
            <p className="text-gray-600">Value or Description</p>
          </div>
        ))}
      </div>

      {/* Button Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div
          className="flex flex-col items-center bg-white p-6 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition duration-300"
          onClick={handleAnnouncementsClick}
        >
          <img
            src={announcementIcon}
            alt="Announcements"
            className="w-16 h-16 mb-4"
          />
          <h4 className="text-lg font-semibold mb-2">Announcements</h4>
          <p className="text-gray-600 text-center">
            View the latest timetable announcements
          </p>
        </div>

        <div
          className="flex flex-col items-center bg-white p-6 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition duration-300"
          onClick={handleRequestClick}
        >
          <img src={requestIcon} alt="Request" className="w-16 h-16 mb-4" />
          <h4 className="text-lg font-semibold mb-2">Request</h4>
          <p className="text-gray-600 text-center">Submit a new request</p>
        </div>

        <div
          className="flex flex-col items-center bg-white p-6 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition duration-300"
          onClick={handleFileUploadClick}
        >
          <img
            src={fileUploadIcon}
            alt="File Upload"
            className="w-16 h-16 mb-4"
          />
          <h4 className="text-lg font-semibold mb-2">File Upload</h4>
          <p className="text-gray-600 text-center">Upload files easily</p>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
