import instance from ".";



const posttable = async (file) => {
  const formData = new FormData();
  formData.append("folder", "tables"); // Define the folder name
  formData.append("file", file); // Append the file itself

  const { data } = await instance.post("/api/files/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
};

const gettables = async () => {
  const { data } = await instance.get("/api/files/all");
  return data;
};

const getLatestTable = async () => {
  const { data } = await instance.get("/api/files/latest/table");
  return data;
};

export default posttable;
export { gettables, getLatestTable };
