// import React from "react";

// const documentsData = [
//   {
//     whoSent: "Lindsay Walton",
//     date: "2023-10-25",
//     time: "14:32",
//     moreInfo: "Project A report",
//     documentUrl: "https://example.com/document1.pdf", // Example URL for document
//   },
//   {
//     whoSent: "Michael Foster",
//     date: "2023-10-24",
//     time: "09:45",
//     moreInfo: "Meeting Notes",
//     documentUrl: "https://example.com/document2.pdf",
//   },
//   // More document entries...
// ];

// const Documents = () => {
//   return (
//     <div className="p-6">
//       <h1 className="text-2xl font-semibold mb-6">Documents</h1>

//       <table className="min-w-full bg-white  border-gray-300 ">
//         <thead>
//           <tr>
//             <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
//               Who Sent
//             </th>
//             <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
//               Date
//             </th>
//             <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
//               Time
//             </th>
//             <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
//               More Info
//             </th>
//             <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
//               Document
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {documentsData.map((document, index) => (
//             <tr key={index} className="border-b border-gray-200">
//               <td className="px-6 py-4 text-sm text-gray-700">
//                 {document.whoSent}
//               </td>
//               <td className="px-6 py-4 text-sm text-gray-700">
//                 {document.date}
//               </td>
//               <td className="px-6 py-4 text-sm text-gray-700">
//                 {document.time}
//               </td>
//               <td className="px-6 py-4 text-sm text-gray-700">
//                 {document.moreInfo}
//               </td>
//               <td className="px-6 py-4 text-sm">
//                 <a
//                   href={document.documentUrl}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-sky-500 hover:text-sky-700 font-medium"
//                 >
//                   Show
//                 </a>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default Documents;
import React, { useEffect, useState } from "react";
import instance from "../api/index";

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const { data } = await instance.get("/api/files/all/doc");
        setDocuments(
          data.map((doc) => ({
            whoSent: doc.user?.fullName || "Unknown", // Display username if available
            date: new Date(doc.createdAt).toISOString().split("T")[0], // Extract date
            time: new Date(doc.createdAt)
              .toISOString()
              .split("T")[1]
              .slice(0, 5), // Extract time
            moreInfo: doc.name, // Use file name as more info
            documentUrl: doc.path, // S3 URL of the file
          }))
        );
        setError(null);
      } catch (err) {
        console.error(
          "Error fetching documents:",
          err.response?.data || err.message
        );
        setError("Failed to fetch documents. Please try again.");
      }
    };

    fetchDocuments();
  }, []);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-6">Documents</h1>

      {error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <table className="min-w-full bg-white border-gray-300">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
                Who Sent
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
                Date
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
                Time
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
                More Info
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-sm font-semibold text-gray-600">
                Document
              </th>
            </tr>
          </thead>
          <tbody>
            {documents.map((document, index) => (
              <tr key={index} className="border-b border-gray-200">
                <td className="px-6 py-4 text-sm text-gray-700">
                  {document.whoSent}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700">
                  {document.date}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700">
                  {document.time}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700">
                  {document.moreInfo}
                </td>
                <td className="px-6 py-4 text-sm">
                  <a
                    href={document.documentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sky-500 hover:text-sky-700 font-medium"
                  >
                    Show
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Documents;
