// src/components/ActionList1.js
import React, { useEffect, useState } from "react";
import { getRequests } from "../api/request";
import { useNavigate } from "react-router";

const ActionList1 = () => {
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [statusFilter, setStatusFilter] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const data = await getRequests();
        setRequests(data);
        setFilteredRequests(data);
      } catch (error) {
        console.error("Failed to fetch requests:", error);
      }
    };

    fetchRequests();
  }, []);

  useEffect(() => {
    let updatedRequests =
      statusFilter === "All"
        ? requests
        : requests.filter((req) => req.status === statusFilter.toLowerCase());

    if (searchTerm) {
      updatedRequests = updatedRequests.filter((req) =>
        req.userId?.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    updatedRequests = [...updatedRequests].sort((a, b) =>
      a._id < b._id ? 1 : -1
    );

    setFilteredRequests(updatedRequests);
  }, [statusFilter, searchTerm, requests]);

  const onClickNavigate = (id) => {
    navigate(`/requestDetails/${id}`);
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const renderStatusDot = (status) => {
    let colorClass = "";
    let glowClass = "";

    switch (status.toLowerCase()) {
      case "pending":
        colorClass = "bg-yellow-400";
        glowClass = "shadow-yellow-400";
        break;
      case "approved":
        colorClass = "bg-green-500";
        glowClass = "shadow-green-500";
        break;
      case "rejected":
        colorClass = "bg-red-500";
        glowClass = "shadow-red-500";
        break;
      default:
        return null;
    }

    return (
      <span
        className={`inline-block w-2.5 h-2.5 rounded-full ${colorClass} ${glowClass} animate-pulse mr-2`}
      ></span>
    );
  };

  return (
    <div className="w-full">
      <div className="bg-white shadow sm:rounded-lg h-[70vh]">
        {/* Fixed Filter Section */}
        <div className="sticky top-0 w-full bg-white z-10 p-4 shadow-md">
          <h2 className="text-base font-semibold leading-7">Requests List</h2>

          <div className="flex items-center space-x-4 mt-4">
            <div>
              <label
                htmlFor="statusFilter"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Filter by Status:
              </label>
              <select
                id="statusFilter"
                value={statusFilter}
                onChange={handleStatusChange}
                className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="All">All</option>
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>

            <div>
              <label
                htmlFor="search"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Search by Employee Name:
              </label>
              <input
                type="text"
                id="search"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Enter employee name"
                className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>

        {/* Table Content Section with Scrollable Container */}
        <div className="h-[55vh] overflow-auto bg-white  ">
          <table className="min-w-full text-left">
            <thead className="sticky top-0 w-full bg-white z-5 border-b border-gray-200 text-sm leading-6">
              <tr>
                <th className="py-2 pl-4 pr-8 font-semibold">Employee Name</th>
                <th className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
                  Request Type
                </th>
                <th className="py-2 pl-0 pr-4 font-semibold">Status</th>
                <th className="py-2 pl-0 pr-4 font-semibold">Action</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-white/5">
              {filteredRequests.map((item, index) => (
                <tr
                  key={item._id || index}
                  className="bg-white border border-gray-200 mb-4"
                >
                  <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8 border-l-4 border-sky-500">
                    <div className="truncate text-sm font-medium leading-6">
                      {item.userId?.fullName || "Unknown User"}
                    </div>
                  </td>
                  <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                    <div className="text-sm text-gray-500">
                      {item.requestType || "N/A"}
                    </div>
                  </td>
                  <td className="py-4 pl-0 pr-4 text-sm  leading-6 flex items-center">
                    {renderStatusDot(item.status)}
                    {item.status}
                  </td>
                  <td className="py-4 pl-0 pr-4 text-sm">
                    <button
                      onClick={() => onClickNavigate(item._id)}
                      className="bg-blue-500 text-white font-semibold py-1 px-3 rounded-md hover:bg-blue-600 focus:outline-none"
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ActionList1;
