import { useState } from "react";
import instance from ".";
import { saveToken } from "./storage";

// Login API call
const login = async (userInfo) => {
  try {
    console.log(userInfo); // Debugging log
    const { data } = await instance.post("/api/auth/login", userInfo);

    if (data.token) {
      saveToken(data.token); // Save the token to storage
    }

    return data;
  } catch (error) {
    console.error(
      "Login error:",
      error.response ? error.response.data : error.message
    );
    throw new Error(error.response?.data?.message || "Login failed");
  }
};

// Register API call
// const register = async (userInfo) => {
//   const formData = new FormData();
//   // for (const key in userInfo) {
//   //   formData.append(key, userInfo[key]);
//   // }
//   formData.append("civilID", userInfo.civilID);
//   console.log(userInfo.civilID);
//   formData.append("fullName", userInfo.fullName);
//   formData.append("email", userInfo.email);
//   // formData.append("password", userInfo.phoneNumber);
//   formData.append("confirmPassword", userInfo.phoneNumber);
//   formData.append("fileNumber", userInfo.fileNumber);
//   formData.append("phoneNumber", userInfo.phoneNumber);
//   formData.append("role", "user");

//   const { data } = await instance.post("/api/auth/register", userInfo);
//   // if (data.token) {
//   //   saveToken(data.token);
//   // }

//   return data;
// };

const register = async (userInfo) => {
  const payload = {
    civilID: userInfo.civilID,
    fullName: userInfo.fullName,
    email: userInfo.email,
    password: userInfo.password, // Use the correct password
    confirmPassword: userInfo.confirmPassword, // Use the correct confirmPassword
    fileNumber: userInfo.fileNumber,
    phoneNumber: userInfo.phoneNumber,
    role: userInfo.role || "user", // Default to "user" if not provided
  };

  const { data } = await instance.post("/api/auth/register", payload);
  return data;
};

const deleteAccountRequest = async (id) => {
  const { data } = await instance.delete(
    `/api/auth//deleteAccountRequest/${id}`
  );
  return data;
};

// Get current user data
const myUser = async () => {
  const { data } = await instance.get("/api/auth/me");
  return data;
};

// Optional: Update user profile
const updateMyProfile = async (updatedInfo) => {
  const formData = new FormData();
  for (const key in updatedInfo) {
    formData.append(key, updatedInfo[key]);
  }

  const { data } = await instance.put("/api/auth/me", formData); // Adjusted route
  return data;
};
// fetch
const fetchUserData = async () => {
  const token = localStorage.getItem("token");
  const { data } = await instance.get("/api/auth/me", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Cache-Control": "no-cache", // This ensures the request fetches fresh data
    },
  });
  return data;
};
//Create a Request
const createRequest = async (request) => {
  const { data } = await instance.post("/api/requests/makerequest", request);
  return data;
};

export {
  login,
  register,
  myUser,
  updateMyProfile,
  fetchUserData,
  createRequest,
  deleteAccountRequest,
};
