import React, { useState, useContext } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import UserContext from "../context/UserContext";
import { deleteToken } from "../api/storage";
import logo from "../assets/DrHLogo3.png";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from "@headlessui/react";
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  UserPlusIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/outline";

const navigation = [
  { name: "Dashboard", path: "/", icon: HomeIcon },
  { name: "Employees", path: "/employees", icon: UsersIcon },
  { name: "Requests", path: "/request", icon: ClipboardDocumentCheckIcon },
  { name: "Table", path: "/timeTable", icon: CalendarIcon },
  { name: "Documents", path: "/documents", icon: DocumentDuplicateIcon },
  { name: "New User Request", path: "/newUserRequest", icon: UserPlusIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    deleteToken();
    setUser(false);
    navigate("/login");
  };

  return (
    <>
      {user ? (
        <div>
          {/* Mobile sidebar */}
          <Dialog
            open={sidebarOpen}
            onClose={setSidebarOpen}
            className="relative z-50 lg:hidden"
          >
            <DialogBackdrop className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0" />

            <div className="fixed inset-0 flex">
              <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full">
                <TransitionChild>
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                    <button
                      type="button"
                      onClick={() => setSidebarOpen(false)}
                      className="-m-2.5 p-2.5"
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        aria-hidden="true"
                        className="h-6 w-6 text-white"
                      />
                    </button>
                  </div>
                </TransitionChild>

                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-sky-600 px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <img alt="Your Company" src={logo} className="h-8 w-auto" />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => {
                            const isActive = location.pathname === item.href;
                            return (
                              <li key={item.name}>
                                <button
                                  onClick={() => navigate(item.path)}
                                  className={classNames(
                                    isActive
                                      ? "bg-sky-700 text-white"
                                      : "text-sky-200 hover:bg-sky-700 hover:text-white w-full",
                                    "group flex gap-x-3 rounded-md p-2 text-sm font-semibold w-full leading-6"
                                  )}
                                >
                                  <item.icon
                                    aria-hidden="true"
                                    className={classNames(
                                      isActive
                                        ? "text-white"
                                        : "text-sky-200 group-hover:text-white",
                                      "h-6 w-full"
                                    )}
                                  />
                                  {item.name}
                                </button>
                              </li>
                            );
                          })}
                          {/* Logout button */}
                          <li>
                            <button
                              onClick={handleLogout}
                              className="text-sky-200 hover:bg-sky-700 hover:text-white group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-full text-left"
                            >
                              <XMarkIcon
                                aria-hidden="true"
                                className="h-6 w-6 text-sky-200 group-hover:text-white"
                              />
                              Logout
                            </button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </DialogPanel>
            </div>
          </Dialog>

          {/* Desktop sidebar */}
          <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-sky-600 px-6">
              <div className="flex h-16 pt-4 shrink-0 items-center">
                <img
                  alt="Your Company"
                  src={logo}
                  className="p-4 h-auto w-full"
                />
              </div>
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {navigation.map((item) => {
                        const isActive = location.pathname === item.href;
                        return (
                          <li key={item.name}>
                            <button
                              onClick={() => navigate(item.path)}
                              className={classNames(
                                isActive
                                  ? "bg-sky-700 text-white"
                                  : "text-sky-200 hover:bg-sky-700 hover:text-white",
                                "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                              )}
                            >
                              <item.icon
                                aria-hidden="true"
                                className={classNames(
                                  isActive
                                    ? "text-white"
                                    : "text-sky-200 group-hover:text-white",
                                  "h-6 w-6 shrink-0"
                                )}
                              />
                              {item.name}
                            </button>
                          </li>
                        );
                      })}
                      {/* Logout button */}
                      <li>
                        <button
                          onClick={handleLogout}
                          className="text-sky-200 hover:bg-sky-700 hover:text-white group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-full text-left"
                        >
                          <XMarkIcon
                            aria-hidden="true"
                            className="h-6 w-6 text-sky-200 group-hover:text-white"
                          />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          {/* Mobile top bar */}
          <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-sky-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
            <button
              type="button"
              onClick={() => setSidebarOpen(true)}
              className="-m-2.5 p-2.5 text-sky-200 lg:hidden"
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
            <div className="flex-1 text-sm font-semibold leading-6 text-white">
              Dashboard
            </div>
          </div>

          <main className="py-6 lg:pl-72 overflow-clip">
            <div className="px-4 sm:px-6 lg:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      ) : (
        <div>Please log in to access this content.</div>
      )}
    </>
  );
}
